/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { axiosInstance } from '../../Axios/https';
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Loader from "../Loader/Loader";
import StripeCheckout from "react-stripe-checkout";

function Header() {
    const [show, setShow] = useState(false);
    const [video, setVideo] = useState([]);
    const [loader, setLoader] = useState(false);
    const [payment, setPayment] = useState(null);
    const [loader1, setLoader1] = useState(false);
    const [radio, setRadio] = useState("stripe");
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('data'));
    console.log(user?.user?.image);
    console.log(token);
    const handlerToogle = () => {
        document.getElementById('user-settings')?.classList?.toggle('active');
        document.getElementById('user-setting-mobile')?.classList?.toggle('active');
    }
    const handlerNav = () => {
        const menu = document.getElementById('menu');
        menu?.classList?.toggle('res-menu');
        menu?.classList?.toggle('mm-menu');
        menu?.classList?.toggle('mm-offcanvas');
    }

    const handlerLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("data");
        toast.success("Logout Successfully");
        window.location.href = "/";
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const fetchData = async () => {
            await CheckPayment();
            AllVideo();
        };
        fetchData();
    }, [payment]);

    async function CheckPayment() {
        setLoader(true);
        try {
            const response = await axiosInstance.get("check-subscribe");
            console.log(response.data?.status, "payment");
            setPayment(response.data?.status);
            setLoader(false);
        } catch (err) {
            console.log(err?.response?.data?.status, "payment");
            setPayment(err?.response?.data?.status);
            setLoader(false);
        }
    }

    async function AllVideo() {
        setLoader(true);
        if (token) {
            try {
                const response = await axiosInstance.get("video", {
                    params: {
                        is_subscribed: payment ? 1 : 0,
                    },
                });
                console.log(response.data?.data);
                setVideo(response.data?.data);
                setLoader(false);
            } catch (err) {
                console.log(err);
                setLoader(false);
            }
        } else {
            try {
                const response = await axiosInstance.get("video-for-userside");
                console.log(response.data?.data);
                setVideo(response.data?.data);
                setLoader(false);
            } catch (err) {
                console.log(err);
                setLoader(false);
            }
        }
    }

    const validationSchema = Yup.object().shape({
        fname: Yup.string().required("FirstName is required"),
        lname: Yup.string().required("LastName is required"),
        country: Yup.string().required("Country is required"),
        number: Yup.number().required("Number is required"),
        email: Yup.string().required("Email is required").email(),
        zip_code: Yup.number().required("zip_code is required"),
        amount: Yup.number().required("Amount is required"),
    });

    const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
        useFormik({
            initialValues: {
                fname: "",
                lname: "",
                country: "",
                number: "",
                email: "",
                zip_code: "",
                amount: "",
            },
            validationSchema,
            onSubmit: (values) => {
                if (!radio) {
                    toast.error("Please select payment method");
                    setLoader1(false);
                } else {
                    console.log(values);
                }
            },
        });

    const onToken = (token) => {
        setLoader1(true);
        console.log(token?.id);
        if (!radio) {
            toast.error("Please select payment method");
            setLoader1(false);
        } else {
            const formData = new FormData();
            formData.append("first_name", values.fname);
            formData.append("last_name", values.lname);
            formData.append("email", values.email);
            formData.append("phone", values.number);
            formData.append("country", values.country);
            formData.append("zip_code", values.zip_code);
            formData.append("price", values.amount);
            formData.append("payment_method", radio);
            formData.append("token", token?.id);
            axiosInstance
                .post("stripe-donation", formData)
                .then((response) => {
                    console.log(response);
                    toast.success(response?.data?.message);
                    setLoader1(false);
                    setShow(false);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message);
                    setLoader1(false);
                });
        }
    };

    const handlerPaypal = () => {
        setLoader1(true);
        if (!radio) {
            toast.error("Please select payment method");
            setLoader1(false);
        } else {
            const formData = new FormData();
            formData.append("first_name", values.fname);
            formData.append("last_name", values.lname);
            formData.append("email", values.email);
            formData.append("phone", values.number);
            formData.append("country", values.country);
            formData.append("zip_code", values.zip_code);
            formData.append("price", values.amount);
            formData.append("payment_method", radio);
            axiosInstance
                .post("paypal-donation", formData)
                .then((response) => {
                    console.log(response?.data?.message);
                    const filter = response?.data?.message?.links?.filter(
                        (fil) => fil.rel === "approve"
                    );
                    console.log(filter[0].href);
                    window.open(filter[0].href, "_blank");
                    toast.success(response?.data?.message);
                    setLoader1(false);
                    setShow(false);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message);
                    setLoader1(false);
                });
        }
    };


    return (
        <>
            <div className="theme-layout">
                <div className="postoverlay" />
                <div className="responsive-header">
                    <div className="mh-head first Sticky">
                        <span className="mh-btns-left">
                            <a className="" href="#" onClick={handlerNav}>
                                <i className="fa fa-align-justify" style={{ color: '#eb0a8c' }} />
                            </a>
                        </span>
                        <span className="mh-text">
                            <a href="#" title="">
                                <img src="/assets/images/logo.png" style={{ borderRadius: '25px' }} className='w-75' alt="" width="100" />
                            </a>
                        </span>
                        <span className="mh-btns-right">
                            <div className="user-img">
                                <img src={user?.user?.image ? user?.user?.image : "/assets/images/resources/admin.png"} className='cursor-pointer rounded-circle' onClick={handlerToogle} alt="" style={{ width: '60px', height: '40px' }} />
                                <span className="status f-online" />
                                <div className="user-setting" id='user-setting-mobile'>
                                    {
                                        token ? (
                                            <>
                                                <Link to={'/viewprofile'} title="">
                                                    <i className="ti-user" /> view profile
                                                </Link>
                                                <a href="#" title="" onClick={handlerLogout}>
                                                    <i className="ti-power-off" />
                                                    log out
                                                </a>
                                            </>
                                        )
                                            : (
                                                <Link to={'/login'} title="">
                                                    <i className="ti-power-off" />
                                                    login
                                                </Link>
                                            )}
                                </div>
                            </div>
                        </span>
                    </div>
                    <nav id="menu" className="res-menu mm-menu mm-offcanvas">
                        <ul className="main-menu">
                            <li className='mt-4'>
                                <Link to={"/"} title="">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={"/about"} title="">
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to={"/subcription"} title="">
                                    Subscription
                                </Link>
                            </li>
                            <li>
                                <Link to={"/contact"} title="">
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <div className="add-btn" onClick={handleShow}>
                                    <a href="#" title="" data-ripple="">
                                        Donation
                                    </a>
                                </div>
                            </li>
                            <li>
                                <Link to="https://www.buymeacoffee.com/pinkconfinement">
                                    <div className="coffee-btn" >
                                        <button>
                                            Buy a coffee
                                        </button>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="topbar stick">
                    <div className="logo">
                        <Link title="" to={"/"}>
                            <img src="/assets/images/logo.png" alt="" width="180" />
                        </Link>
                    </div>
                    <div className="top-area">
                        <ul className="main-menu">
                            <li>
                                <Link to={"/"} title="">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={"/about"} title="">
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to={"/subcription"} title="">
                                    Subscription
                                </Link>
                            </li>
                            <li>
                                <Link to={"/contact"} title="">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div className="user-img">
                            {
                                token ? (
                                    <>
                                        <img src={user?.user?.image ? user?.user?.image : "/assets/images/resources/admin.png"} onClick={handlerToogle} alt="" style={{ width: '60px', height: '60px' }} />
                                        <span className="status f-online" />
                                        <div className="user-setting" id='user-settings'>
                                            <Link to={'/viewprofile'} title="">
                                                <i className="ti-user" /> view profile
                                            </Link>
                                            <a href="#" title="" onClick={handlerLogout}>
                                                <i className="ti-power-off" />
                                                log out
                                            </a>
                                        </div>
                                    </>
                                )
                                    : (
                                        <Link to={'/login'} title="" style={{
                                            background: 'deeppink',
                                            color: 'white',
                                            padding: '15px 30px',
                                            borderRadius: '25px'
                                        }}>
                                            login
                                        </Link>
                                    )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Add Donate</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="text"
                                        name="fname"
                                        onChange={handleChange}
                                        value={values.fname}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        First Name
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">{touched.fname && errors.fname}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="text"
                                        name="lname"
                                        onChange={handleChange}
                                        value={values.lname}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Last Name
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">{touched.lname && errors.lname}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="text"
                                        name="country"
                                        onChange={handleChange}
                                        value={values.country}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Country Name
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">
                                        {touched.country && errors.country}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="number"
                                        name="number"
                                        onChange={handleChange}
                                        value={values.number}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Number
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">
                                        {touched.number && errors.number}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Email
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">{touched.email && errors.email}</span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="number"
                                        name="amount"
                                        onChange={handleChange}
                                        value={values.amount}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Amount
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">
                                        {touched.amount && errors.amount}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group w-100 my-4">
                                    <input
                                        type="number"
                                        name="zip_code"
                                        onChange={handleChange}
                                        value={values.zip_code}
                                        onBlur={handleBlur}
                                    />
                                    <label className="control-label" htmlFor="input">
                                        Zip Code
                                    </label>
                                    <i className="mtrl-select" />
                                    <span className="error">
                                        {touched.zip_code && errors.zip_code}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <Form className="d-flex justify-content-around align-items-center">
                                    <Form.Check
                                        type={"radio"}
                                        label={`Stripe`}
                                        value={"stripe"}
                                        className="my-4"
                                        name="radio"
                                        onClick={() => setRadio("stripe")}
                                    />
                                    <Form.Check
                                        type={"radio"}
                                        label={`Paypal`}
                                        name="radio"
                                        value={"paypal"}
                                        onClick={() => setRadio("paypal")}
                                    />
                                </Form>
                            </div>
                        </div>

                        <div className="submit-btns">
                            <button
                                className="mtr-btn signup"
                                style={{ background: "#eb0a8c", width: "100%" }}
                                type="submit"
                            >
                                {loader1 ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {radio == "stripe" ? (
                                            <StripeCheckout
                                                token={(token) => onToken(token)}
                                                stripeKey="pk_test_51OM5QgHIC6YSoZmKJYiuVcSsPM6QHO7iVNjQi5t0mSudvWzgSdos55tgQyTOmaFrEqdzHYBYpf7SB6aMGahsfVkn00xlfczKqR"
                                            >
                                                <span className="text-white mx-2">Add Donate</span>
                                            </StripeCheckout>
                                        ) : (
                                            <>
                                                <span
                                                    onClick={handlerPaypal}
                                                    className="text-white mx-2"
                                                >
                                                    Paypal
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Header
// https://codepen.io/piyushpd139/pen/KKqqXPv


