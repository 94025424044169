import About from "../Components/About"
import Layout from "../Components/Layout/Layout"

function AboutPage () {
    return(
        <>
        <Layout>
            <About />
        </Layout>
        </>
    )
}

export default AboutPage


