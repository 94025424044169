/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './thanks.css'
import { Link } from 'react-router-dom'

function Reject() {
    return (
        <>
            <div className='reject'>
                <h1>Reject</h1>
                <Link class="button" to={"/"}>
                    <i class="icon-home"></i> Go to Home.
                </Link>
            </div>
        </>
    )
}

export default Reject

