/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import './profile.css'
import { axiosInstance } from "../../Axios/https";
import { useEffect, useState } from "react";

function ViewProfile() {
    const user = JSON.parse(localStorage.getItem('data'));
    console.log(user?.user);
    const [endDate , setEndDate] = useState('');

    const handlerEndDateSubcription = async () => {
     const response = await axiosInstance.get('/subscribe');
     const filter = response.data.data.filter((fil) => fil.status == 1);
     console.log(filter[0]);
     setEndDate(filter[0]);
    }
    useEffect(()=>{
        handlerEndDateSubcription();
    },[]);
    return (
        <>
            <Layout>
                <div className="container">
                    <div className="row d-flex justify-content-center py-5">
                        <div className="col-md-7">
                            <div className="card p-3 py-4">
                                <div className="shadow rounded py-4">
                                <div className="text-center">
                                    <img
                                        src={user?.user?.image ? user?.user?.image : "https://i.imgur.com/bDLhJiP.jpg"}
                                        style={{width: "100px", height: "100px"}}
                                        className="rounded-circle"
                                    />
                                </div>
                                <div className="text-center mt-3" style={{lineHeight:4}}>
                                    <h6 className="fs-4 fw-bold mb-4">Your Subcription Detail</h6>
                                    <div className="d-flex flex-md-row flex-column align-items-center justify-content-around mx-md-4 mx-0">
                                    <div>
                                    <h6 className="fs-5 fw-normal">{user?.user?.name}</h6>
                                    <h6 className="fs-5 fw-normal">{user?.user?.email}</h6>
                                    </div>
                                    <div>
                                    <h6 className="fs-5 fw-normal">Start Date : {endDate?.start_date ? endDate?.start_date : 'N/A'}</h6>
                                    <h6 className="fs-5 fw-normal">End Date : {endDate?.end_date ? endDate?.end_date : 'N/A'}</h6>
                                    </div>
                                    </div>
                                    <div className="buttons">
                                        <Link to={'/editprofile'}>
                                        <button className="btn btn-outline-primary px-4">Edit Profile</button>
                                        </Link>
                                        <Link to={'/changepassword'}> 
                                        <button className="btn btn-primary px-4 ms-3">Change Password</button>
                                        </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ViewProfile

