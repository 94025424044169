import React from 'react'
import Layout from '../Components/Layout/Layout'
import Subcription from '../Components/Subcription/Subcription'

function SubcriptionPage  () {
  return (
    <Layout>
      <Subcription />
    </Layout>
  )
}

export default SubcriptionPage


