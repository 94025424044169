import * as Yup from "yup";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../Axios/https";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loader from "../Loader/Loader";

/* eslint-disable jsx-a11y/anchor-is-valid */
function Login() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is required"),
    password: Yup.string().required("password is required"),
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: (values) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("role", 'user');
        axiosInstance
          .post("login", formData)
          .then((res) => {
            console.log(res?.data?.data);
            toast.success(res?.data?.data?.message);
            const data = JSON.stringify(res?.data?.data);
            localStorage.setItem("token", res?.data?.data?.accessToken);
            localStorage.setItem("data", data);
            navigate("/");
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
            setLoader(false);
          });
      },
    });

  return (
    <>
      <section style={{ background: '#eb0a8c', height: 'auto' }}>
        <div className="gap mt-5 overlap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="contact-form contct-info shadow-none rounded">
                    <div className="cnt-title">
                      <h2 className="text-center fw-bold">Login</h2>
                      <i>
                        <img src="images/envelop.png" alt="" />
                      </i>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group w-100">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <label className="control-label" htmlFor="input">
                          Email
                        </label>
                        <i className="mtrl-select" />
                        <span className="error">{touched.email && errors.email}</span>
                      </div>
                      <div className="form-group w-100">
                        <input
                          type="password"
                          name="password"
                          placeholder="password"
                          onChange={handleChange}
                          value={values.password}
                          onBlur={handleBlur}
                        />
                        <label className="control-label" htmlFor="input">
                          Password
                        </label>
                        <i className="mtrl-select" />
                        <span className="error">
                          {touched.password && errors.password}
                        </span>
                      </div>
                      <Link to={"/forgotpassword"}>
                        <span className="cursor-pointer" style={{ color: '#088DCD', fontSize: '16px' , lineHeight: 3 }}>Forgot Password?</span>
                      </Link>  <br />
                      <Link to={"/signup"}>
                        <span className="cursor-pointer" style={{ color: '#088DCD', fontSize: '16px' }}>Create your account?</span>
                      </Link>
                      <div className="submit-btns">
                        <button type="submit" className="mtr-btn signup" style={{ background: '#eb0a8c' , width:'150px' }}>
                          {
                            loader ? <Loader /> :
                              <>
                                <span className="text-white mx-2">Login</span><i className="fa fa-paper-plane" />
                              </>
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login



