import axios from "axios";
// import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: 'https://pinkconfinementapi.dev-sh.xyz/api/',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const RESP = error.response.data;
    if (RESP.error) {
      // toast.error(RESP.error);
    } else {
      // toast.error(error.message);
    }
    return Promise.reject(error);
  }
);