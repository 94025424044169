import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loader from "../../Components/Loader/Loader";
import Layout from "../../Components/Layout/Layout";
import { axiosInstance } from "../../Axios/https";


/* eslint-disable jsx-a11y/anchor-is-valid */
function ChangePassword() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const validationSchema = Yup.object().shape({
        current_password: Yup.string().required("Current Password is required"),
        password: Yup.string().required("Password is required"),
        password_confirmation: Yup.string().required("Password Confirmation is required"),
    });

    const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
        useFormik({
            initialValues: {
                current_password: "",
                password: "",
                password_confirmation: "",
            },
            validationSchema,
            onSubmit: (values) => {
                setLoader(true);
                const formData = new FormData();
                formData.append("current_password", values.current_password);
                formData.append("password", values.password);
                formData.append("password_confirmation", values.password_confirmation);
                axiosInstance
                    .post("change-password", formData)
                    .then((res) => {
                        console.log(res?.data);
                        toast.success(res?.data?.message);
                        navigate("/viewprofile");
                        setLoader(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(err.response.data.message);
                        setLoader(false);
                    });
            },
        });

    return (
        <>
            <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto py-4">
                            <div className="central-meta">
                                <div>
                                    <h2 className="f-title mb-0">
                                        <i className="ti-lock" />
                                        Change Password
                                    </h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input id="input"
                                                type="password"
                                                name="current_password"
                                                // placeholder="Enter your Current Password"
                                                onChange={handleChange}
                                                value={values.current_password}
                                                onBlur={handleBlur}
                                            />
                                            <label className="control-label" htmlFor="input">
                                                Current password
                                            </label>
                                            <i className="mtrl-select" />
                                            <span className="error">{touched.current_password && errors.current_password}</span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="password"
                                                //  placeholder="Enter your Password"
                                                onChange={handleChange}
                                                value={values.password}
                                                onBlur={handleBlur}
                                            />
                                            <label className="control-label" htmlFor="input">
                                                Password
                                            </label>
                                            <i className="mtrl-select" />
                                            <span className="error">{touched.password && errors.password}</span>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                //  placeholder="Enter your Password Confirmation"
                                                onChange={handleChange}
                                                value={values.password_confirmation}
                                                onBlur={handleBlur}
                                            />
                                            <label className="control-label" htmlFor="input">
                                                Password Confirmation
                                            </label>
                                            <i className="mtrl-select" />
                                            <span className="error">{touched.password_confirmation && errors.password_confirmation}</span>
                                        </div>
                                        <div className="submit-btns">
                                            <button type="submit" className="mtr-btn signup" style={{ background: '#eb0a8c', width: '160px' }}>
                                                {
                                                    loader ? <Loader /> :
                                                        <>
                                                            <span>Update</span>
                                                        </>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ChangePassword