import React from 'react'
import HeroSection01 from '../Components/HeroSection01'
import Layout from '../Components/Layout/Layout'

function HomePage() {
  return (
    <Layout>
      <HeroSection01 />
    </Layout>
  )
}

export default HomePage



