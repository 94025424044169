/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../Axios/https";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Loader from "../Components/Loader/Loader";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import StripeCheckout from "react-stripe-checkout";
import { Link } from "react-router-dom";

function HeroSection01() {
  const [video, setVideo] = useState([]);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [show, setShow] = useState(false);
  const [payment, setPayment] = useState(null);
  const [radio, setRadio] = useState("stripe");
  const token = localStorage.getItem("token");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function CheckPayment() {
    setLoader(true);
    try {
      const response = await axiosInstance.get("check-subscribe");
      console.log(response.data?.status, "payment");
      setPayment(response.data?.status);
      setLoader(false);
    } catch (err) {
      console.log(err?.response?.data?.status, "payment");
      setPayment(err?.response?.data?.status);
      setLoader(false);
    }
  }

  async function AllVideo() {
    setLoader(true);
    if (token) {
      try {
        const response = await axiosInstance.get("video", {
          params: {
            is_subscribed: payment ? 1 : 0,
          },
        });
        console.log(response.data?.data);
        setVideo(response.data?.data);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    } else {
      try {
        const response = await axiosInstance.get("video-for-userside");
        console.log(response.data?.data);
        setVideo(response.data?.data);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await CheckPayment();
      AllVideo();
    };
    fetchData();
  }, [payment]);

  const VideoLike = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("type", "post");
      console.log(id);
      const res = await axiosInstance.post(`like`, formData);
      console.log(res);
      AllVideo();
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPress = (event, id) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmitComment(id);
      setComment("");
    }
  };

  const handleSubmitComment = async (id) => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("post_id", id);
      formData.append("comment", comment);
      const res = await axiosInstance.post("comment", formData);
      console.log(res);
      toast.success(res.data.message);
      AllVideo();
      setComment("");
      setLoader(false);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
      setLoader(false);
    }
  };

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required("FirstName is required"),
    lname: Yup.string().required("LastName is required"),
    country: Yup.string().required("Country is required"),
    number: Yup.number().required("Number is required"),
    email: Yup.string().required("Email is required").email(),
    zip_code: Yup.number().required("zip_code is required"),
    amount: Yup.number().required("Amount is required"),
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        fname: "",
        lname: "",
        country: "",
        number: "",
        email: "",
        zip_code: "",
        amount: "",
      },
      validationSchema,
      onSubmit: (values) => {
        if (!radio) {
          toast.error("Please select payment method");
          setLoader1(false);
        } else {
          console.log(values);
        }
      },
    });

  const onToken = (token) => {
    setLoader1(true);
    console.log(token?.id);
    if (!radio) {
      toast.error("Please select payment method");
      setLoader1(false);
    } else {
      const formData = new FormData();
      formData.append("first_name", values.fname);
      formData.append("last_name", values.lname);
      formData.append("email", values.email);
      formData.append("phone", values.number);
      formData.append("country", values.country);
      formData.append("zip_code", values.zip_code);
      formData.append("price", values.amount);
      formData.append("payment_method", radio);
      formData.append("token", token?.id);
      axiosInstance
        .post("stripe-donation", formData)
        .then((response) => {
          console.log(response);
          toast.success(response?.data?.message);
          setLoader1(false);
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          setLoader1(false);
        });
    }
  };

  const handlerPaypal = () => {
    setLoader1(true);
    if (!radio) {
      toast.error("Please select payment method");
      setLoader1(false);
    } else {
      const formData = new FormData();
      formData.append("first_name", values.fname);
      formData.append("last_name", values.lname);
      formData.append("email", values.email);
      formData.append("phone", values.number);
      formData.append("country", values.country);
      formData.append("zip_code", values.zip_code);
      formData.append("price", values.amount);
      formData.append("payment_method", radio);
      axiosInstance
        .post("paypal-donation", formData)
        .then((response) => {
          console.log(response?.data?.message);
          const filter = response?.data?.message?.links?.filter(
            (fil) => fil.rel === "approve"
          );
          console.log(filter[0].href);
          window.open(filter[0].href, "_blank");
          toast.success(response?.data?.message);
          setLoader1(false);
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          setLoader1(false);
        });
    }
  };



  return (
    <>
      <div className="theme-layout">
        <section>
          <div className="feature-photo">
            <figure>
              <img
                className="timeline-bg"
                src="/assets/images/resources/Biggest Platform Around3.png"
                alt=""
              />
            </figure>
          </div>
          <div className="banner-buttons">
            <div className="add-btn  d-none d-lg-block" onClick={handleShow}>
              <a href="#" title="" data-ripple="">
                Donation
              </a>
            </div>
            <Link to="https://www.buymeacoffee.com/pinkconfinement" className=" d-none d-lg-block">
              <div className="coffee-btn" >
                <button>
                  Buy a coffee
                </button>
              </div>
            </Link>
          </div>
        </section>
        {loader ? (
          <div className="text-center">
            <ColorRing
              visible={true}
              height="100"
              width="100"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#EB0A8C", "#EBs0A8C", "#EB0A8C", "#EB0A8C", "#EB0A8C"]}
            />
          </div>
        ) : (
          <section>
            <div className="gap gray-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row merged20" id="page-contents">
                      <div className="col-lg-10 mx-auto">
                        <div className="loadMore">
                          {video?.map((video) => (
                            <div className="central-meta item">
                              <div className="user-post">
                                <div className="friend-info">
                                  <figure>
                                    <img
                                      src="/assets/images/resources/team4.jpg"
                                      alt=""
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </figure>
                                  <div className="friend-name">
                                    <ins>
                                      <a href="#" title="">
                                        {video?.title}
                                      </a>
                                    </ins>
                                    <span>published: {video?.schedule_date}</span>
                                  </div>
                                  <div className="post-meta">
                                    <iframe
                                      width=""
                                      height={285}
                                      src={video?.video}
                                      frameBorder={0}
                                      allowFullScreen=""
                                    />
                                    {token ? (
                                      <div className="we-video-info">
                                        <ul>
                                          <li>
                                            <span
                                              className="comment"
                                              data-toggle="tooltip"
                                              title="Comments"
                                            >
                                              <i className="ti-comment-alt" />
                                              <ins>
                                                {video?.comments?.length}
                                              </ins>
                                            </span>
                                          </li>
                                          <li>
                                            <span
                                              onClick={() =>
                                                VideoLike(video?.id)
                                              }
                                              className="dislike"
                                              data-toggle="tooltip"
                                              title="dislike"
                                            >
                                              <i className="ti-heart" />
                                              <ins>
                                                {video?.post_likes?.length}
                                              </ins>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : null}
                                    <div className="description text-left">
                                      <p className="fs-6 mb-2 text-left">
                                        {video?.desc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {token ? (
                                  <div className="coment-area">
                                    <ul className="we-comet">
                                      {video?.comments?.map((comment) => (
                                        <>
                                          <li>
                                            <div className="comet-avatar">
                                              <img
                                                src={
                                                  comment?.comment_user?.image
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  width: "40px",
                                                }}
                                              />
                                            </div>
                                            <div className="we-comment">
                                              <div className="coment-head">
                                                <h5>
                                                  <a
                                                    href="#"
                                                    title=""
                                                  >
                                                    {
                                                      comment?.comment_user
                                                        ?.name
                                                    }
                                                  </a>
                                                </h5>
                                                <span>{comment?.date}</span>
                                              </div>
                                              <p className="text-left">
                                                {comment?.comment}
                                              </p>
                                            </div>
                                          </li>
                                        </>
                                      ))}
                                      <li className="post-comment">
                                        <div className="comet-avatar">
                                          <img
                                            src="/assets/images/resources/team4.jpg"
                                            alt=""
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </div>
                                        <div className="post-comt-box">
                                          <form>
                                            <input
                                              className="comment-input"
                                              type="text"
                                              value={comment}
                                              onChange={(e) =>
                                                setComment(e.target.value)
                                              }
                                              placeholder="Enter your comment"
                                              onKeyPress={(e) =>
                                                handleKeyPress(e, video?.id)
                                              }
                                            />
                                            <button
                                              onClick={() =>
                                                handleSubmitComment(video?.id)
                                              }
                                              style={{
                                                background: "deeppink",
                                                color: "white",
                                                height: "47px",
                                                marginRight: "2px",
                                              }}
                                              type="button"
                                            >
                                              {loader ? <Loader /> : "Send"}
                                            </button>
                                          </form>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Add Donate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="text"
                    name="fname"
                    onChange={handleChange}
                    value={values.fname}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    First Name
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">{touched.fname && errors.fname}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="text"
                    name="lname"
                    onChange={handleChange}
                    value={values.lname}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Last Name
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">{touched.lname && errors.lname}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="text"
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Country Name
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">
                    {touched.country && errors.country}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="number"
                    name="number"
                    onChange={handleChange}
                    value={values.number}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Number
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">
                    {touched.number && errors.number}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Email
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">{touched.email && errors.email}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="number"
                    name="amount"
                    onChange={handleChange}
                    value={values.amount}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Amount
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">
                    {touched.amount && errors.amount}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group w-100 my-4">
                  <input
                    type="number"
                    name="zip_code"
                    onChange={handleChange}
                    value={values.zip_code}
                    onBlur={handleBlur}
                  />
                  <label className="control-label" htmlFor="input">
                    Zip Code
                  </label>
                  <i className="mtrl-select" />
                  <span className="error">
                    {touched.zip_code && errors.zip_code}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <Form className="d-flex justify-content-around align-items-center">
                  <Form.Check
                    type={"radio"}
                    label={`Stripe`}
                    value={"stripe"}
                    className="my-4"
                    name="radio"
                    onClick={() => setRadio("stripe")}
                  />
                  <Form.Check
                    type={"radio"}
                    label={`Paypal`}
                    name="radio"
                    value={"paypal"}
                    onClick={() => setRadio("paypal")}
                  />
                </Form>
              </div>
            </div>

            <div className="submit-btns">
              <button
                className="mtr-btn signup"
                style={{ background: "#eb0a8c", width: "100%" }}
                type="submit"
              >
                {loader1 ? (
                  <Loader />
                ) : (
                  <>
                    {radio == "stripe" ? (
                      <StripeCheckout
                        token={(token) => onToken(token)}
                        stripeKey="pk_test_51OM5QgHIC6YSoZmKJYiuVcSsPM6QHO7iVNjQi5t0mSudvWzgSdos55tgQyTOmaFrEqdzHYBYpf7SB6aMGahsfVkn00xlfczKqR"
                      >
                        <span className="text-white mx-2">Add Donate</span>
                      </StripeCheckout>
                    ) : (
                      <>
                        <span
                          onClick={handlerPaypal}
                          className="text-white mx-2"
                        >
                          Paypal
                        </span>
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HeroSection01;

// f , l , c , n , e , zip , am ,
