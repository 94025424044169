/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./subcription.css";
import { axiosInstance } from "../../Axios/https";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import StripeCheckout from "react-stripe-checkout";
import { useNavigate } from "react-router-dom";

function Subcription() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [packages, setPackage] = useState([]);
  const [radio, setRadio] = useState("stripe");
  const tokens = localStorage.getItem("token");

  useEffect(() => {
    setLoader(true);
    axiosInstance
      .get("package")
      .then((res) => {
        console.log(res.data.data);
        setPackage(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []);

  const onToken = (token, id) => {
    setLoader(true);
    console.log(token?.id, id);
    if (!radio) {
      toast.error("Please select payment method");
      setLoader(false);
    } else {
      const formData = new FormData();
      formData.append("package_id", id);
      formData.append("payment_method", radio);
      formData.append("token", token?.id);
      axiosInstance
        .post("stripe-subscribe", formData)
        .then((response) => {
          console.log(response);
          toast.success(response?.data?.message);
          navigate("/");
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  const handlerPaypal = (id) => {
    setLoader(true);
    if (!radio) {
      toast.error("Please select payment method");
      setLoader(false);
    } else {
      const formData = new FormData();
      formData.append("package_id", id);
      formData.append("payment_method", radio);
      axiosInstance
        .post("paypal-subscribe", formData)
        .then((response) => {
          console.log(response?.data?.message);
          const filter = response?.data?.message?.links?.filter(
            (fil) => fil.rel === "approve"
          );
          console.log(filter[0].href);
          window.open(filter[0].href, "_blank");
          toast.success(response?.data?.message);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  return (
    <>
      {loader ? (
        <>
          <div style={{ height: "80vh" }}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <ColorRing
                visible={true}
                height="120"
                width="120"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#EB0A8C", "#EB0A8C", "#EB0A8C", "#EB0A8C", "#EB0A8C"]}
              />
            </div>
          </div>
        </>
      ) : (
        <section className="pricing-section">
          <div className="pricing">
            <div className="pricing-body">
              <div className="pricing-body-plans">
                <div className="active" id="pricing__monthly__plan">
                  <div className="container mt-5 mb-5">
                    {packages?.map((item) => {
                      return (
                        <>
                          <div className="card mt-5 mb-3 mt-md-0">
                            <div className="card-header">
                              <h2
                                className="card-price"
                                style={{ fontSize: "30px" }}
                              >
                                ${item?.price}/{item?.duration_period}
                              </h2>
                            </div>
                            <div className="card-body text-center">
                              <h2
                                className="card-price"
                                style={{ fontSize: "30px" }}
                              >
                                {item?.name}
                              </h2>
                              <p className="fs-6">{item?.description}</p>
                            </div>
                            <div className="card-footer flex-column">
                              <Form className="d-flex justify-content-around align-items-center">
                                <Form.Check
                                  type={"radio"}
                                  label={`Stripe`}
                                  value={"stripe"}
                                  className="my-4"
                                  name="radio"
                                  onClick={() => setRadio("stripe")}
                                />
                                <Form.Check
                                  type={"radio"}
                                  label={`Paypal`}
                                  name="radio"
                                  value={"paypal"}
                                  className="mt-1 ms-2"
                                  onClick={() => setRadio("paypal")}
                                />
                              </Form>
                              <>
                                {radio == "stripe" ? (
                                  tokens ? (
                                    <StripeCheckout
                                      token={(token) =>
                                        onToken(token, item?.id)
                                      }
                                      stripeKey="pk_test_51OM5QgHIC6YSoZmKJYiuVcSsPM6QHO7iVNjQi5t0mSudvWzgSdos55tgQyTOmaFrEqdzHYBYpf7SB6aMGahsfVkn00xlfczKqR"
                                    >
                                      <button className="text-white mx-2">
                                        Package Buy
                                      </button>
                                    </StripeCheckout>
                                  ) : (
                                    <button
                                      onClick={() => navigate("/login")}
                                      className="text-white mx-2"
                                    >
                                      Package Buy
                                    </button>
                                  )
                                ) : (
                                  <>
                                    <button
                                      onClick={() => handlerPaypal(item?.id)}
                                      className="text-white mx-2"
                                    >
                                      Package Buy
                                    </button>
                                  </>
                                )}
                              </>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Subcription;
