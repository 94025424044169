import React from 'react'
import Login from '../../Components/Auth/Login'

function LoginPage () {
  return (
    <>
      <Login />
    </>
  )
}

export default LoginPage



