import * as Yup from "yup";
import { useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loader from "../../Components/Loader/Loader";
import { axiosInstance } from "../../Axios/https";

function ResetPassword () {
    const location = useLocation();
    console.log(location?.state?.email);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
  
    const validationSchema = Yup.object().shape({
      code: Yup.string().required("code is required"),
      password: Yup.string().required("Password is required"),
      password_confirmation: Yup.string().required("Password_confirmation is required"),
    });
  
    const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
      useFormik({
        initialValues: {
            code: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema,
        onSubmit: (values) => {
          setLoader(true);
          const formData = new FormData();
          formData.append("email", location?.state?.email);
          formData.append("code", values.code);
          formData.append("password", values.password);
          formData.append("password_confirmation", values.password_confirmation);
          axiosInstance
            .post("reset-password", formData)
            .then((res) => {
              console.log(res?.data);
              toast.success(res?.data?.message);
              navigate("/login");
              setLoader(false);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data.message);
              setLoader(false);
            });
        },
      });
    return (
     <>
       <section style={{ background: '#eb0a8c', height: '100vh' }} className="d-flex justify-content-center align-items-center">
        <div className="gap mt-5 overlap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="contact-form contct-info shadow-none rounded">
                    <div className="cnt-title">
                      <h2 className="text-center fw-bold">Reset Password</h2>
                      <i>
                        <img src="images/envelop.png" alt="" />
                      </i>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group w-100">
                        <input
                          type="number"
                          name="code"
                          placeholder="Enter your Code"
                          onChange={handleChange}
                          value={values.code}
                          onBlur={handleBlur}
                        />
                        <i className="mtrl-select" />
                        <span className="error">{touched.code && errors.code}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter your Password"
                          onChange={handleChange}
                          value={values.password}
                          onBlur={handleBlur}
                        />
                        <i className="mtrl-select" />
                        <span className="error">{touched.password && errors.password}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password_confirmation"
                          placeholder="Enter your Password_confirmation"
                          onChange={handleChange}
                          value={values.password_confirmation}
                          onBlur={handleBlur}
                        />
                        <i className="mtrl-select" />
                        <span className="error">{touched.password_confirmation && errors.password_confirmation}</span>
                      </div>
                      <div className="submit-btns">
                        <button type="submit" className="mtr-btn signup" style={{ background: '#eb0a8c' ,width:'220px' }}>
                          {
                            loader ? <Loader /> :
                              <>
                                <span className="text-white mx-2">Reset Password</span><i className="fa fa-paper-plane" />
                              </>
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     </>
    )
 }
 export default ResetPassword
 