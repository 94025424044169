/* eslint-disable jsx-a11y/anchor-is-valid */

function About() {
  return (
    <>
      <section>
        <div className="gap gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row merged20" id="page-contents">
                  <div className="col-lg-10 mx-auto">
                    <div className="central-meta">
                      <div className="about">
                        <div className="personal">
                          <h2 className="f-title fs-2">About Info</h2>
                          <p
                            style={{
                              fontSize: "17px",
                              color: "black",
                              fontWeight: 500,
                              fontFamily: "sans-serif",
                              letterSpacing: "2px",
                            }}
                          >
                            Pink Confinement is a universal and diverse platform
                            that provides inclusion and a safe space for people
                            to share their unorthodox life experiences with a
                            concentration of resilient women and returning
                            citizens. Each guest of Pink Confinement shares the
                            original unscripted story of their life events in
                            hopes of providing encouragement and being a
                            positive reinforcement in the lives of others.
                          </p>
                        </div>
                        {/* <div className="d-flex flex-row mt-2">
                          <ul className="nav nav-tabs nav-tabs--vertical nav-tabs--left">
                            <li className="nav-item">
                              <a
                                href="#"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                About info
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                style={{ color: "deeppink" }}
                                href="#"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                location
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                style={{ color: "deeppink" }}
                                href="#"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                work and education
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                style={{ color: "deeppink" }}
                                href="#"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                interests
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                style={{ color: "deeppink" }}
                                href="#"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                languages
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="basic"
                            >
                              <ul
                                className="basics"
                                style={{ color: "deeppink" }}
                              >
                                <li style={{ color: "deeppink" }}>
                                  <i
                                    className="ti-user"
                                    style={{ color: "deeppink" }}
                                  />
                                  Dummy Name
                                </li>
                                <li style={{ color: "deeppink" }}>
                                  <i
                                    className="ti-map-alt"
                                    style={{ color: "deeppink" }}
                                  />
                                  Live in Dummy
                                </li>
                                <li style={{ color: "deeppink" }}>
                                  <i
                                    className="ti-mobile"
                                    style={{ color: "deeppink" }}
                                  />
                                  0034033903903
                                </li>
                                <li style={{ color: "deeppink" }}>
                                  <i
                                    className="ti-email"
                                    style={{ color: "deeppink" }}
                                  />
                                  <a
                                    href="https://www.wpkixx.com/cdn-cgi/l/email-protection"
                                    className="__cf_email__"
                                    data-cfemail="96eff9e3e4fbf7fffad6f3fbf7fffab8f5f9fb"
                                  >
                                    [email&nbsp;protected]
                                  </a>
                                </li>
                                <li style={{ color: "deeppink" }}>
                                  <i
                                    className="ti-world"
                                    style={{ color: "deeppink" }}
                                  />
                                  www.dummy.com
                                </li>
                              </ul>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="location"
                              role="tabpanel"
                            >
                              <div className="location-map">
                                <div id="map-canvas" />
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="work"
                              role="tabpanel"
                            >
                              <div>
                                <a href="#" title="">
                                  Envato
                                </a>
                                <p>
                                  work as autohr in envato themeforest from 2013
                                </p>
                                <ul className="education">
                                  <li>
                                    <i className="ti-facebook" /> BSCS from
                                    Oxford University
                                  </li>
                                  <li>
                                    <i className="ti-twitter" /> MSCS from
                                    Harvard Unversity
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="interest"
                              role="tabpanel"
                            >
                              <ul className="basics">
                                <li>Footbal</li>
                                <li>internet</li>
                                <li>photography</li>
                              </ul>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="lang"
                              role="tabpanel"
                            >
                              <ul className="basics">
                                <li>english</li>
                                <li>french</li>
                                <li>spanish</li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
